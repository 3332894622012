var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-4" }, [
    _c("h4", { staticClass: "header" }, [_vm._v("\n    Email Settings\n  ")]),
    _c("div", { staticClass: "col-md-12 mt-4 pl-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md" }, [
          _c("span", { staticClass: "user-settings" }, [
            _vm._v("\n          Username:\n        "),
          ]),
          _c("p", { staticClass: "setting-value" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.emailAddress || "----") + "\n        "
            ),
          ]),
        ]),
        _vm._m(0),
      ]),
    ]),
    _c(
      "ul",
      {
        staticClass: "nav nav-tabs",
        attrs: { id: "setting-tabs", role: "tablist" },
      },
      [
        _c("li", { staticClass: "nav-item", attrs: { role: "presentation" } }, [
          _c(
            "button",
            {
              staticClass: "nav-link active",
              attrs: {
                id: "secure-settings-tab",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
              },
              on: {
                click: function ($event) {
                  return _vm.changePortTo("secure-settings-tab")
                },
              },
            },
            [_vm._v("\n        Secure SSL Settings (Recommended)\n      ")]
          ),
        ]),
        _c("li", { staticClass: "nav-item", attrs: { role: "presentation" } }, [
          _c(
            "button",
            {
              staticClass: "nav-link",
              attrs: {
                id: "non-ssl-settings-tab",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
              },
              on: {
                click: function ($event) {
                  return _vm.changePortTo("non-ssl-settings-tab")
                },
              },
            },
            [_vm._v("\n        Non-SSL Settings\n      ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "email-settings-container row pt-4 mb-5" }, [
      _c("div", { staticClass: "col-md-6 server-details pt-0" }, [
        _vm._m(1),
        _c("div", { staticClass: "col-md-12 pl-0" }, [
          _c("span", { staticClass: "setting-name" }, [
            _vm._v("\n          Server HostName:\n        "),
          ]),
          _c("p", { staticClass: "setting-value" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.serverName || "----") + "\n        "
            ),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("span", { staticClass: "setting-name" }, [
              _vm._v("\n            IMAP Port:\n          "),
            ]),
            _c("p", { staticClass: "setting-value" }, [
              _vm._v("\n            " + _vm._s(_vm.imapPort) + "\n          "),
            ]),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _c("span", { staticClass: "setting-name" }, [
              _vm._v("\n            POP3 Port:\n          "),
            ]),
            _c("p", { staticClass: "setting-value" }, [
              _vm._v("\n            " + _vm._s(_vm.pop3Port) + "\n          "),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-md-6 server-details pt-0" }, [
        _vm._m(2),
        _c("div", { staticClass: "col-md-12 pl-0" }, [
          _c("span", { staticClass: "setting-name" }, [
            _vm._v("\n          Server HostName:\n        "),
          ]),
          _c("p", { staticClass: "setting-value" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.serverName || "----") + "\n        "
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-md-12 pl-0" }, [
          _c("span", { staticClass: "setting-name" }, [
            _vm._v("\n          SMTP Port:\n        "),
          ]),
          _c("p", { staticClass: "setting-value" }, [
            _vm._v("\n          " + _vm._s(_vm.smtpPort) + "\n        "),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md" }, [
      _c("span", { staticClass: "user-settings" }, [
        _vm._v("\n          Password:\n        "),
      ]),
      _c("p", { staticClass: "setting-value font-italic" }, [
        _vm._v("\n          Use the email account's password\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 server-name-div" }, [
      _c("span", { staticClass: "server-name" }, [
        _vm._v("\n          Incoming Mail Server\n        "),
      ]),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 server-name-div" }, [
      _c("span", { staticClass: "server-name" }, [
        _vm._v("\n          Outgoing Mail Server\n        "),
      ]),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }